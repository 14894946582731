




















import Vue, { PropType } from 'vue'
import { TimerStatus } from '@/model/timer'

export default Vue.extend({
  name: 'CountdownTimer',
  computed: {
    valueLabel (): object {
      const oneDec = this.timer.remainingSeconds.toFixed(1)
      const intVal = oneDec.substring(0, oneDec.length - 2)
      const decVal = oneDec.substring(oneDec.length - 1)
      return {
        integer: intVal,
        decimal: decVal
      }
    },
    valuePct (): number {
      return Math.round(100.0 * this.timer.remainingSeconds / this.timer.durationSeconds)
    }
  },
  props: {
    timer: {
      type: Object as PropType<TimerStatus>,
      required: true
    }
  }
})
