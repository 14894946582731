import { StringUtils } from '@/utils/string'

interface BaseFormField {
  type: string;
  sort?: number;
}

export interface TextFormField extends BaseFormField {
  type: 'text';
  textType?: 'text' | 'datetime' | 'date' | 'month' | 'time' | 'number' | 'email' | 'tel' | 'color';
  label?: string;
  placeholder?: string;
  required?: boolean;
  note?: string;
  autocomplete?: boolean;
  maxLength?: number;
}

const defaultTextFormField = (fieldName: string): TextFormField => {
  return {
    type: 'text',
    label: StringUtils.splitCamelCase(fieldName) + ':',
    textType: 'text',
    required: false,
    note: '',
    autocomplete: true,
    maxLength: 100
  }
}

function isText (field: BaseFormField): field is TextFormField {
  return field.type === 'text'
}

export interface TextAreaFormField extends BaseFormField {
  type: 'textarea';
  label?: string;
  placeholder?: string;
  required?: boolean;
  rows?: number;
}

const defaultTextAreaFormField = (fieldName: string): TextAreaFormField => {
  return {
    type: 'textarea',
    label: StringUtils.splitCamelCase(fieldName) + ':',
    required: false,
    rows: 3
  }
}

function isTextArea (field: BaseFormField): field is TextAreaFormField {
  return field.type === 'textarea'
}

export interface CheckboxFormField extends BaseFormField {
  type: 'checkbox';
  text?: string;
  checkedValue: string;
  uncheckedValue?: string;
  defaultChecked: boolean;
}

const defaultCheckboxFormField = (fieldName: string): CheckboxFormField => {
  return {
    type: 'checkbox',
    checkedValue: 'yes',
    uncheckedValue: 'no',
    defaultChecked: false,
    text: StringUtils.splitCamelCase(fieldName)
  }
}

function isCheckbox (field: BaseFormField): field is CheckboxFormField {
  return field.type === 'checkbox'
}

export interface SelectFormField extends BaseFormField {
  type: 'select';
  label: string;
  options: { label: string; value: string}[];
  required?: boolean;
}

const defaultSelectFormField = (fieldName: string): SelectFormField => {
  return {
    type: 'select',
    label: StringUtils.splitCamelCase(fieldName),
    required: false,
    options: []
  }
}

function isSelect (field: BaseFormField): field is SelectFormField {
  return field.type === 'select'
}

export type FormField = TextFormField | TextAreaFormField | CheckboxFormField | SelectFormField

export type FormFields = { [fieldName: string]: FormField }

export interface RegistrationConfig {
  allowInGame: boolean;
  captureFields: FormFields;
}

const defaultRegConfig: RegistrationConfig = {
  allowInGame: false,
  captureFields: {}
}

// Take the configuration object and return an ordered Map
// Sorts fields by their sort value (with secondary sort on fieldName)
export function getOrderedFormFields (fields: FormFields): Map<string, FormField> {
  const sortedFieldNames = []
  for (const key in fields) {
    sortedFieldNames.push({
      fieldName: key,
      sort: fields[key].sort ?? 999
    })
  }
  sortedFieldNames.sort((l, r) => {
    return l.sort - r.sort || l.fieldName.localeCompare(r.fieldName)
  })
  const sortedFields = new Map<string, FormField>()
  sortedFieldNames.forEach(f => {
    sortedFields.set(f.fieldName, fields[f.fieldName])
  })
  return sortedFields
}
export function createFullRegConfig (cfg?: RegistrationConfig): RegistrationConfig {
  // 1) Overlay provided config onto default root config
  const fullConfig = Object.assign(defaultRegConfig, cfg)

  // 2) Ensure there is a field named playerName in captureFields
  if (!fullConfig.captureFields?.playerName) {
    fullConfig.captureFields.playerName = {
      type: 'text',
      label: 'Player Name:',
      required: true,
      maxLength: 50,
      sort: -1
    }
  }

  // 3) Ensure immutable playerName fields are correct
  // (this allows other attributes of this field to be customized)
  const playerNameField = fullConfig.captureFields.playerName as TextFormField
  playerNameField.type = 'text'
  playerNameField.textType = 'text'
  playerNameField.required = true
  playerNameField.sort = -1

  // 4) Default each field to type 'text' if necessary
  // 5) Ensure each field in captureFields has appropriate defaults
  const resultantCaptureFields: FormFields = {}
  for (const fieldName in fullConfig.captureFields) {
    const fieldConfig = fullConfig.captureFields[fieldName]
    // Default to a 'text' field
    fieldConfig.type = fieldConfig.type ?? 'text'
    // Set per-type defaults
    if (isText(fieldConfig)) {
      resultantCaptureFields[fieldName] = Object.assign(defaultTextFormField(fieldName), fieldConfig)
    } else if (isTextArea(fieldConfig)) {
      resultantCaptureFields[fieldName] = Object.assign(defaultTextAreaFormField(fieldName), fieldConfig)
    } else if (isCheckbox(fieldConfig)) {
      resultantCaptureFields[fieldName] = Object.assign(defaultCheckboxFormField(fieldName), fieldConfig)
    } else if (isSelect(fieldConfig) && (fieldConfig.options && fieldConfig.options.length > 0)) {
      resultantCaptureFields[fieldName] = Object.assign(defaultSelectFormField(fieldName), fieldConfig)
    }
  }
  fullConfig.captureFields = resultantCaptureFields

  return fullConfig
}
