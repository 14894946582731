
































import Vue from 'vue'
import { ViewPhase, AnswerState } from '@/model/gamephase'

export default Vue.extend({
  name: 'MultipleSimplePoints',
  data () {
    return {
      ViewPhase,
      AnswerState // expose Enums to render
    }
  },
  props: {
    correctPointValue: {
      type: Number,
      required: true
    },
    actualPointsOutcome: {
      type: Number,
      required: false
    },
    viewPhase: {
      type: String as () => ViewPhase | undefined,
      required: true
    },
    playerView: {
      type: Boolean,
      default: true
    }
  }
})
