


































































import Vue from 'vue'
import { PropType } from 'vue/types/umd'
import { FormField, FormFields, getOrderedFormFields } from '@/model/registration'
import DynamicForm from '@/components/form/DynamicForm.vue'
import { mapGetters, mapState } from 'vuex'
import GamePhase from '@/model/gamephase'
import store from '@/store'
import { LocalStorage } from '@/utils/localstorage'
import { TimeUtils } from '@/utils/time'

const REG_FORM_CACHE_KEY = 'regFormCache'

function initializeFormValues (
  formData: { [fieldName: string]: string },
  fields: FormFields,
  gameInstanceId: string,
  useCache: boolean
) {
  // initialize form values for each field
  for (const fieldName in fields) {
    // const fieldConfig = fields[fieldName]
    // TODO: for some field types, initialize with default value?
    if (!formData[fieldName]) {
      formData[fieldName] = ''
    }
  }
  if (useCache) {
    try {
      const cachedForm = LocalStorage.getObject(gameInstanceId, REG_FORM_CACHE_KEY)
      Object.assign(formData, cachedForm)
    } catch (err) {
      console.error('Unable to load reg form cache', err)
    }
  }
}

export default Vue.extend({
  name: 'PlayerRegistration',
  components: {
    DynamicForm
  },
  computed: {
    orderedFields (): Map<string, FormField> {
      return getOrderedFormFields(this.fields)
    },
    showJustWatch (): boolean {
      if (store.state.user.isRegistered) {
        return false
      }
      return true
    },
    ...mapGetters(['userIsRegistering']),
    ...mapState(['gamePhase', 'gameInstanceId', 'user'])
  },
  beforeMount () {
    // We need to ensure we initialize the property in the values state object
    // for each configured field
    initializeFormValues(this.values, this.fields, this.gameInstanceId, !this.formUsed)
  },
  watch: {
    fields: function (newValue: FormFields) {
      // In case field configs change, need to ensure we initialize the
      // property in the values state object for each configured field
      initializeFormValues(this.values, newValue, this.gameInstanceId, !this.formUsed)
    },
    userIsRegistering (newValue: boolean, oldValue: boolean) {
      if (oldValue && !newValue) {
        const topOfReg = (this.$refs.topOfReg as HTMLElement)
        topOfReg.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    }
  },
  props: {
    fields: {
      type: Object as PropType<FormFields>,
      required: true
    }
  },
  data () {
    return {
      GamePhase,
      showWelcomeImage: true,
      formUsed: false,
      values: {
      } as { [fieldName: string]: string }
    }
  },
  methods: {
    regSubmit () {
      LocalStorage.saveObject(store.state.gameInstanceId, REG_FORM_CACHE_KEY, this.values, TimeUtils.hoursMs(1))
      store.dispatch.login(this.values)
    },
    justWatch () {
      store.dispatch.justWatch(true)
    },
    hideWelcomeImage () {
      this.showWelcomeImage = false
    },
    firstFormUse () {
      this.formUsed = true
    }
  }
})
