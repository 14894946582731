














































import { SingleTextQuestionSpec } from '@/model/question'
import StandardLayoutQuestion from '@/components/question/layout/StandardLayoutQuestion.vue'
import QuestionHeader from '@/components/misc/QuestionHeader.vue'
import QuestionText from '@/components/misc/QuestionText.vue'
import SingleTextInput from '@/components/inputs/SingleTextInput.vue'
import SingleAnswerStats from '@/components/stats/SingleAnswerStats.vue'
import SimplePoints from '@/components/points/SimplePoints.vue'
import QuestionNotes from '@/components/misc/QuestionNotes.vue'
import { BaseQuestion } from '@/components/question/baseQuestion'
import computeSingleAnswerOutcome from '@/components/mixins/computeSingleAnswerOutcome'

export default BaseQuestion<SingleTextQuestionSpec>().extend({
  name: 'SingleTextQuestion',
  components: {
    StandardLayoutQuestion,
    SingleTextInput,
    SingleAnswerStats,
    SimplePoints,
    QuestionNotes,
    QuestionHeader,
    QuestionText
  },
  mixins: [computeSingleAnswerOutcome]
})
