import { render, staticRenderFns } from "./AnswerStateIcon.vue?vue&type=template&id=f2edf4b8&scoped=true&"
import script from "./AnswerStateIcon.vue?vue&type=script&lang=ts&"
export * from "./AnswerStateIcon.vue?vue&type=script&lang=ts&"
import style0 from "./AnswerStateIcon.vue?vue&type=style&index=0&id=f2edf4b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2edf4b8",
  null
  
)

export default component.exports