import Vue from 'vue'
import { LocalStorage } from '@/utils/localstorage'
import { TimeUtils } from '@/utils/time'

export interface BaseInputProps {
  gameInstanceId: string;
  uniqueKey: string;
  answeringEnabled: boolean;
}

interface BIData {
  answers: string[];
}

interface BIMethods {
  initializeData(): void;
  handleInputAnswerChange (answerLabel?: string[]): void;
}

function getCachedAnswerKey (qKey: string): string {
  return `qcache.${qKey}`
}

export function BaseInput () {
  return Vue.extend<BIData, BIMethods, object, BaseInputProps>({
    name: 'BaseInput',
    data () {
      return {
        answers: []
      }
    },
    props: {
      answeringEnabled: {
        type: Boolean,
        required: true
      },
      gameInstanceId: {
        type: String,
        required: true
      },
      uniqueKey: {
        type: String,
        required: true
      }
    },
    created () {
      // First initialize data (if necessary)
      this.initializeData()
      // Then re-establish saved answers (if present)
      if (this.answeringEnabled) {
        const cachedAnswer = LocalStorage.getObject(this.gameInstanceId, getCachedAnswerKey(this.uniqueKey))
        if (cachedAnswer) {
          this.answers = cachedAnswer.answers
          this.handleInputAnswerChange(cachedAnswer.answerLabels)
        }
      }
    },
    methods: {
      initializeData () {
        // Re-implement this method if you need to initialize data.answers
      },
      handleInputAnswerChange (answerLabels?: string[]): void {
        const answersArr = Array.from(this.answers)
        // Emit answer change to Question (baseQuestion.handleQuestionAnswerChange)
        this.$emit('onChange', answersArr, answerLabels)
        LocalStorage.saveObject(
          this.gameInstanceId,
          getCachedAnswerKey(this.uniqueKey),
          {
            answers: answersArr,
            answerLabels
          },
          TimeUtils.hoursMs(4))
      }
    }
  })
}
