






import Vue from 'vue'
import { ViewPhase, AnswerState } from '@/model/gamephase'

interface IconClasses {
  mainClass: string;
  iconClass?: string;
}

const ICON_MAP: { [key: string]: IconClasses } = {
  [AnswerState.CORRECT]: {
    mainClass: 'correct',
    iconClass: 'fa-check'
  },
  [AnswerState.INCORRECT]: {
    mainClass: 'incorrect',
    iconClass: 'fa-times'
  },
  [AnswerState.SKIP]: {
    mainClass: 'skip',
    iconClass: 'fa-angle-double-right'
  },
  [AnswerState.NOANSWER]: {
    mainClass: 'noanswer',
    iconClass: 'fa-question-circle'
  }
}

export default Vue.extend({
  name: 'AnswerStateIcon',
  props: {
    viewPhase: {
      type: String as () => ViewPhase,
      required: true
    },
    answerState: {
      type: String as () => AnswerState,
      required: false
    }
  },
  computed: {
    cls (): IconClasses {
      if (this.viewPhase === ViewPhase.ANSWER && ICON_MAP[this.answerState]) {
        return ICON_MAP[this.answerState]
      }
      return {
        mainClass: '',
        iconClass: ''
      }
    }
  },
  data: function () {
    return {
      ViewPhase // expose Enums to template render
    }
  }
})
