import { Expose } from 'class-transformer'
import { QuestionSpec, QuestionType } from '@/model/question'

export class MultipleTextQuestionSpec extends QuestionSpec {
  @Expose() readonly playerAnswerCount: number
  @Expose() readonly category: string
  @Expose() readonly subCategory: string
  @Expose() readonly questionText: string
  @Expose() readonly correctPointValue: number

  constructor (
    type: QuestionType,
    playerAnswerCount: number,
    category: string,
    subCategory: string,
    questionText: string,
    correctPointValue: number
  ) {
    super(type)
    this.category = category
    this.playerAnswerCount = playerAnswerCount
    this.subCategory = subCategory
    this.questionText = questionText
    this.correctPointValue = correctPointValue
  }
}
