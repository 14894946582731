/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-namespace */
interface ExpirableObject<VALUE> {
  creationTime: number;
  expirationTime: number;
  value: VALUE;
}

function isExpirableObject<VALUE> (obj: ExpirableObject<VALUE>): obj is ExpirableObject<VALUE> {
  return obj.creationTime > 0 && obj.expirationTime > 0 && obj.value !== undefined
}

function getNamespacedKey (namespace: string, key: string): string {
  return `${namespace}.${key}`
}

export namespace LocalStorage {

  // Normally the namespace used will be a Live Game ID/UUID, but if we need a global value
  // then we should use this namespace
  export const GLOBAL_NAMESPACE = 'risio'

  /**
   * Store an object in local storage.
   * @param namespace namespace for this key, should either be "GLOBAL" or a LiveGameUUID
   * @param key
   * @param value
   * @param ttlMillis TTL in milliseconds from now
   * @returns true if object was successfully saved
   */
  export function saveObject<VALUE> (namespace: string, key: string, value: VALUE, ttlMillis: number): boolean {
    const fullKey = getNamespacedKey(namespace, key)
    const nowMillis = Date.now()
    const expirationMillis = nowMillis + ttlMillis
    const expObj: ExpirableObject<VALUE> = {
      creationTime: nowMillis,
      expirationTime: expirationMillis,
      value: value
    }
    try {
      localStorage.setItem(fullKey, JSON.stringify(expObj))
      return true
    } catch (err) {
      // console.error('[localstorage] Unable to save object', err)
      return false
    }
  }

  /**
   * Retrieve an object from local storage.
   * TODO: It would be nice to have this method enforce type safety of the retrieved value
   * @param namespace namespace for this key, should either be "GLOBAL" or a LiveGameUUID
   * @param key
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function getObject (namespace: string, key: string): any | null {
    const nowMillis = Date.now()
    const fullKey = getNamespacedKey(namespace, key)
    const objString = localStorage.getItem(fullKey)

    if (objString === null) {
      return null
    }
    let obj
    try {
      obj = JSON.parse(objString)
    } catch (err) {
      // Likely saved to local storage outside of this code
      // Just pass the raw string back
      return objString
    }
    if (isExpirableObject(obj)) {
      if (nowMillis >= obj.expirationTime) {
        localStorage.removeItem(fullKey)
        return null
      } else {
        return obj.value
      }
    } else {
      console.debug(`[localstorage] Retrieved value for key "${fullKey}" is not an ExpirableObject`)
      return objString
    }
  }

  /**
   * Remove all local objects that have outlived their TTL.
   * @param removeUnknownObjects force remove any object that is not a ExpirableObject?
   */
  export function expireOldObjects (removeUnknownObjects = false): void {
    const nowMillis = Date.now()
    const totalObjects = localStorage.length
    const keysToRemove = []
    for (let i = 0; i < totalObjects; i++) {
      const key = localStorage.key(i)
      if (key !== null) {
        const objString = localStorage.getItem(key)
        if (objString !== null) {
          let isJson = false
          let obj = null
          try {
            obj = JSON.parse(objString)
            isJson = true
          } catch (err) {
            isJson = false
          }
          if (isJson && isExpirableObject(obj)) {
            if (nowMillis >= obj.expirationTime) {
              keysToRemove.push(key)
            }
          } else if (removeUnknownObjects) {
            keysToRemove.push(key)
          } else {
            // NOP. Leave unknown objects alone
          }
        }
      }
    }
    keysToRemove.forEach(key => {
      localStorage.removeItem(key)
    })
    console.debug(`[localstorage] Expired ${keysToRemove.length} of ${totalObjects} object(s)`)
  }

}
