export const DEFAULT_TIMER_SECONDS = 15.0
export const DEFAULT_TIMER_INTERVAL_MS = 150
const MIN_TIMER_INTERVAL_MS = 100

export interface TimerStatus {
  durationSeconds: number;
  remainingSeconds: number;
  isRunning: boolean;
}
export default class Timer {
  private _durationSeconds: number
  private _remainingSeconds: number
  private _running = false
  private _intervalMs: number
  private _timerStartEpochMs = 0
  private _timerTickTimeoutHandle?: number

  constructor (durationSeconds?: number, remainingSeconds?: number, intervalMs?: number) {
    this._durationSeconds = durationSeconds || DEFAULT_TIMER_SECONDS
    this._remainingSeconds = remainingSeconds || DEFAULT_TIMER_SECONDS
    this._intervalMs = Math.max(intervalMs || DEFAULT_TIMER_INTERVAL_MS, MIN_TIMER_INTERVAL_MS)
  }

  public reset (durationSeconds: number) {
    this.stop()
    this._durationSeconds = durationSeconds
    this._remainingSeconds = durationSeconds
  }

  // Starts timer and returns true if timer newly started or false if timer already running
  public start (): boolean {
    if (this._running) {
      return false
    }
    this._timerStartEpochMs = Date.now()
    this._running = true
    this.scheduleTick()
    return true
  }

  private scheduleTick () {
    this._timerTickTimeoutHandle = setTimeout(() => this.tick(), this._intervalMs)
  }

  private tick () {
    const newRemainingSeconds = this._durationSeconds - ((Date.now() - this._timerStartEpochMs) / 1000)
    if (newRemainingSeconds <= (this._intervalMs / 1000 / 2)) {
      this._remainingSeconds = 0
      this._running = false
    } else {
      this._remainingSeconds = newRemainingSeconds
      this.scheduleTick()
    }
  }

  public stop () {
    this._remainingSeconds = 0
    this._running = false
    if (this._timerTickTimeoutHandle) {
      clearTimeout(this._timerTickTimeoutHandle)
    }
  }

  get isRunning (): boolean {
    return this._running
  }

  get remainingSeconds (): number {
    return this._remainingSeconds
  }

  get durationSeconds (): number {
    return this._durationSeconds
  }

  get status (): TimerStatus {
    return {
      remainingSeconds: this.remainingSeconds,
      durationSeconds: this.durationSeconds,
      isRunning: this.isRunning
    }
  }
}
