




































import Vue from 'vue'

export default Vue.extend({
  name: 'PercentFilledBar',
  computed: {
    cssVars (): {[name: string]: string} {
      return {
        '--main-color': this.scolor,
        '--border-radius': this.sborderradius,
        '--border-width': this.sborderwidth,
        '--border-opacity': this.sborderopacity,
        '--border-color': this.sbordercolor || this.scolor
      }
    },
    roundedPercent (): string {
      return Math.round(this.percent).toFixed(0)
    }
  },
  props: {
    percent: {
      type: Number,
      required: true
    },
    label: {
      type: [String, Number],
      required: true
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    scolor: {
      type: String,
      default: '#0077ff'
    },
    sborderradius: {
      type: String,
      default: '5px'
    },
    sborderwidth: {
      type: String,
      default: '2px'
    },
    sborderopacity: {
      type: String,
      default: '1'
    },
    sbordercolor: {
      type: String,
      required: false
    }
  }
})
