







import Vue from 'vue'

export default Vue.extend({
  name: 'QuestionNotes',
  props: {
    label: {
      type: String,
      required: false,
      default: 'Notes:'
    },
    notesText: {
      type: String,
      required: true
    }
  }
})
