import { Expose } from 'class-transformer'
import { QuestionSpec, QuestionType } from '@/model/question'

export class SecondGuessTextQuestionSpec extends QuestionSpec {
  @Expose() readonly category: string
  @Expose() readonly subCategory: string
  @Expose() readonly questionText: string
  @Expose() readonly correctPointValue: number
  @Expose() readonly incorrectPointValue: number

  get playerAnswerCount (): number {
    return 2
  }

  constructor (
    type: QuestionType,
    category: string,
    subCategory: string,
    questionText: string,
    correctPointValue: number,
    incorrectPointValue: number
  ) {
    super(type)
    this.category = category
    this.subCategory = subCategory
    this.questionText = questionText
    this.correctPointValue = correctPointValue
    this.incorrectPointValue = incorrectPointValue
  }
}
