/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-namespace */

export namespace StringUtils {

  export function splitCamelCase (cc: string): string {
    const output = []
    const capitalRe = /[A-Z]/

    for (let i = 0, l = cc.length; i < l; i += 1) {
      if (i === 0) {
        output.push(cc[i].toUpperCase())
      } else {
        if (i > 0 && capitalRe.test(cc[i])) {
          output.push(' ')
        }
        output.push(cc[i])
      }
    }
    return output.join('')
  }

}
