export enum GamePhase {
  OFF = 'OFF',
  PRE_GAME = 'PRE_GAME',
  IN_GAME = 'IN_GAME',
  POST_GAME = 'POST_GAME'
}

export default GamePhase

export enum ViewPhase {
  QUESTION = 'QUESTION',
  ANSWER = 'ANSWER'
}

export enum AnswerState {
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
  SKIP = 'SKIP',
  NOANSWER = 'NOANSWER',
  // Only used by FE
  SELECTED = 'SELECTED',
  UNSELECTED = 'UNSELECTED'
}
