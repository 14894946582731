import { AnswerState, ViewPhase } from '@/model/gamephase'
import { QuestionSpec } from '@/model/question'
import { RenderedQuestion } from '@/model/round'
import Vue from 'vue'
import { PropType } from 'vue/types/umd'

const computeSingleAnswerOutcome = Vue.extend({
  props: {
    renderedQuestion: {
      type: RenderedQuestion as PropType<RenderedQuestion<QuestionSpec>>
    }
  },
  computed: {
    singleAnswerOutcome (): AnswerState | undefined {
      if (this.renderedQuestion.viewPhase !== ViewPhase.ANSWER) {
        return undefined
      }
      return this.renderedQuestion.getSubmittedAnswerOutcome(0)
    }
  }
})

export default computeSingleAnswerOutcome
