













































































































import Vue, { VueConstructor } from 'vue'
import { QuestionSpec } from '@/model/question'
import Timer from '@/model/timer'
import CountdownTimer from '@/components/misc/CountdownTimer.vue'
import { ViewPhase } from '@/model/gamephase'
import JokerOption from '@/components/misc/JokerOption.vue'
import { getQuestionComponent, QUESTION_COMPONENTS } from '@/components/question/questionComponentMap'
import { RenderedQuestion, RenderedRound, RoundQuestionMode } from '@/model/round'
import { User } from '@/model/user'
import { UIUtils } from '@/utils/ui'
import { debounce } from 'debounce'
import { AugmentedWindow } from '@/main'
import { SubmittedAnswer, SubmittedAnswersMessage } from '@/model/rpc'

type AnswerRecordMap = { [questionIx: number]: AnswerRecord }
interface AnswerRecord {
  value: string[];
  label: string;
}

type DebouncedFunc<F extends Function> = F & { clear(): void } & { flush(): void }

export default Vue.extend({
  name: 'PlayerRound',
  components: {
    JokerOption,
    CountdownTimer,
    ...QUESTION_COMPONENTS
  },
  created () {
    // create a local variable so we can unregister it when we destroy the component
    this.scrollBounce = debounce(this.handleScroll, 50)
    window.addEventListener('scroll', this.scrollBounce)
  },
  beforeDestroy () {
    if (this.scrollBounce) {
      window.removeEventListener('scroll', this.scrollBounce)
    }
  },
  updated () {
    this.handleScroll()
  },
  watch: {
    round: function <Q extends QuestionSpec> (newValue?: RenderedRound, oldValue?: RenderedRound) {
      // When to reset jokerActive flag
      if (newValue === undefined || newValue === null) {
        this.jokerActive = false
      } else if (!newValue.showSubmitButton || oldValue?.roundIx !== newValue?.roundIx) {
        // We've switched between rounds
        this.jokerActive = false
      }

      // When entering ANSWER phase, scroll to top of the round
      const previousPhase = oldValue?.getRoundPhase()
      const newPhase = newValue?.getRoundPhase()
      if (newPhase === ViewPhase.ANSWER && previousPhase !== ViewPhase.ANSWER && !this.isVideoPresent) {
        const topEl = (this.$refs.topOfRound as HTMLElement)
        topEl.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    }
  },
  data: function () {
    return {
      // Keeps the most recently recorded answer for each question in this game (across rounds)
      // These answers may or may not have been submitted
      recordedAnswers: {} as AnswerRecordMap,
      jokerActive: false,
      roundDescriptionOpenState: {} as { [roundIx: number]: boolean },
      scrollBounce: undefined as DebouncedFunc<(this: Window, ev: Event) => void> | undefined
    }
  },
  props: {
    gameInstanceId: {
      type: String,
      required: true
    },
    round: {
      type: RenderedRound,
      required: false
    },
    timer: {
      type: Timer,
      required: false
    },
    user: {
      type: User,
      required: false
    }
  },
  computed: {
    showQuickNav (): boolean {
      return this.round?.renderedQuestions.length > 1 && this.round?.roundLength > 1 && this.round?.roundQuestionMode !== RoundQuestionMode.ONE
    },
    showJoker (): boolean {
      return this.round?.joker !== undefined && this.round.joker !== null && this.round.joker.show
    },
    // Return all RenderedQuestions in the current round which are in the
    // QUESTION phase and are UNLOCKED
    openQuestions (): RenderedQuestion<QuestionSpec>[] {
      return this.round?.renderedQuestions.filter((rq: RenderedQuestion<QuestionSpec>) => {
        return rq.viewPhase === ViewPhase.QUESTION && !rq.locked
      })
    },
    displaySubmittedAnswers (): object[] {
      const ans: object[] = []
      for (let i = 0; i < this.round?.renderedQuestions?.length; i++) {
        const rq = this.round.renderedQuestions[i]
        if (rq.viewPhase === ViewPhase.QUESTION && !rq.locked && rq.hasSubmittedAnswers()) {
          ans.push({
            questionIx: rq.questionIx,
            label: rq.questionLabel,
            answer: rq.getSubmittedAnswersLabel()
          })
        }
      }
      return ans
    },
    isCurrentRoundDescriptionOpen (): boolean {
      return this.roundDescriptionOpenState[this.round.roundIx] || this.roundDescriptionOpenState[this.round.roundIx] === undefined
    },
    isVideoPresent (): boolean {
      return document.getElementsByClassName('risio-embedded-video').length > 0 ?? false
    }
  },
  methods: {
    currentRoundDescriptionOpenToggle (e: Event): void {
      Vue.set(this.roundDescriptionOpenState, this.round.roundIx, (e.target as HTMLElement).hasAttribute('open'))
    },
    questionComponentType (idx: number): VueConstructor<Vue> {
      const qType = this.round.renderedQuestions[idx].question.type
      const cType = getQuestionComponent(qType)
      return cType
    },
    // This is called when any answer input changes
    // For multichoice, this happens on selection of an answer
    // For textanswer, this happens on every keystroke
    //
    // TODO: For one-question rounds, we may want to auto-submit
    // when multichoice answer is selected or <ENTER> is hit in
    // textanswer to remove the need to manually press Submit Answer button
    recordAnswer (questionIx: number, answers: string[], answerLabels: string[] = answers) {
      const combinedLabel = answerLabels.join(', ')
      this.recordedAnswers[questionIx] = {
        value: answers,
        label: combinedLabel
      }
    },
    submitAnswers <Q extends QuestionSpec> () {
      // Collect currently open questions
      const questions: RenderedQuestion<QuestionSpec>[] = this.openQuestions

      // Collect answers for current questions
      const answersToSubmit = new SubmittedAnswersMessage(this.round.roundIx, this.jokerActive)
      questions.forEach((question) => {
        if (this.recordedAnswers[question.questionIx]) {
          const answer = this.recordedAnswers[question.questionIx]
          answersToSubmit.addSubmittedAnswer(new SubmittedAnswer(question.questionIx, answer.value))
        }
      })

      if (window) {
        (window as AugmentedWindow).shinyReceiveAnswers?.(answersToSubmit)
        console.debug('Submitted Answers: ', answersToSubmit)
      }
    },
    quickNavTop (e: Event) {
      UIUtils.scrollToTop()
      const target = e?.target as HTMLElement
      target?.blur()
    },
    quickNav (ref: string, highlight: true, e: Event) {
      const el = UIUtils.getHTMLElementFromRef(this.$refs[ref])
      UIUtils.scrollToAndHighlight(el, highlight)
      const target = e?.target as HTMLElement
      target?.blur()
    },
    handleScroll () {
      // the following logic is to check whether or not we want to pin the submitAnswer button
      const lowerWrap = (this.$refs.lowerWrap as HTMLElement)
      const lower = (this.$refs.lower as HTMLElement)
      if (lower && lowerWrap) {
        let firstQuestionInView = false
        if (this.round?.renderedQuestions?.length > 0) {
          const firstQuestionEl = UIUtils.getHTMLElementFromRef(this.$refs[this.round.renderedQuestions[0].questionIx])
          if (firstQuestionEl) {
            firstQuestionInView = firstQuestionEl?.getBoundingClientRect().top + firstQuestionEl.offsetHeight < window.innerHeight
          }
        }
        if (firstQuestionInView) {
          if (lowerWrap?.getBoundingClientRect().top + lowerWrap.offsetHeight < window.innerHeight) {
            lower.classList.remove('outOfView')
          } else {
            lower.classList.add('outOfView')
          }
        } else {
          // we don't want to run the logic unless the first question is in view, when .outOfView is removed, the lower is unpinned
          lower.classList.remove('outOfView')
        }
      }
      // check to pin the right nav or not (if the wrap is scrolled off the top of the screen)
      const rightNavWrap = (this.$refs.previewNavWrap as HTMLElement)
      const rightNav = (this.$refs.previewNav as HTMLElement)
      if (rightNav && rightNavWrap) {
        // 15 to give it some padding
        if (rightNavWrap.getBoundingClientRect().top <= 15) {
          rightNav.classList.add('pinned')
        } else {
          rightNav.classList.remove('pinned')
        }
      }
    }
  }
})
