














































import { MultipleSelectQuestionSpec } from '@/model/question'
import StandardLayoutQuestion from '@/components/question/layout/StandardLayoutQuestion.vue'
import QuestionHeader from '@/components/misc/QuestionHeader.vue'
import QuestionText from '@/components/misc/QuestionText.vue'
import MultipleSelectInput from '@/components/inputs/MultipleSelectInput.vue'
import QuestionNotes from '@/components/misc/QuestionNotes.vue'
import SimplePoints from '@/components/points/SimplePoints.vue'
import { BaseQuestion } from '@/components/question/baseQuestion'
import StatsByPoints from '@/components/stats/StatsByPoints.vue'
import { ViewPhase } from '@/model/gamephase'

export default BaseQuestion<MultipleSelectQuestionSpec>().extend({
  name: 'MultipleSelectQuestion',
  components: {
    StandardLayoutQuestion,
    MultipleSelectInput,
    SimplePoints,
    QuestionNotes,
    QuestionText,
    QuestionHeader,
    StatsByPoints
  },
  data () {
    return {
      ViewPhase
    }
  }
})
