import { Expose, Type } from 'class-transformer'
import { QuestionType } from '@/model/question'

export type KeyedAnswerTotals = { [answerKey: string]: AnswerTotals}

/**
 * This represents how many people gave a particular answer.
 *
 * @field proportion - The proportion of the larger group, [0, 1]
 * @field count - The number of answers in this group
 */
export class AnswerTotals {
  @Expose() readonly proportion: number
  @Expose() readonly count: number

  constructor (proportion = 0, count = 0) {
    this.proportion = proportion
    this.count = count
  }
}

/**
 * This provides group answer stats for single-answer questions
 */
export class SingleAnswerStats {
  @Expose() readonly type: QuestionType // needed?
  @Expose() readonly correctAnswerKey?: string[]
  @Expose() readonly correctAnswerLabel?: string
  @Expose() readonly answerTotals?: KeyedAnswerTotals // stats by answer

  constructor (
    type: QuestionType,
    correctAnswerKey?: string | string[],
    correctAnswerLabel?: string,
    answerTotals?: KeyedAnswerTotals
  ) {
    this.type = type
    if (correctAnswerKey && correctAnswerKey instanceof String) {
      this.correctAnswerKey = [...correctAnswerKey]
    } else if (correctAnswerKey instanceof Array) {
      this.correctAnswerKey = correctAnswerKey
    }
    this.correctAnswerLabel = correctAnswerLabel
    this.answerTotals = answerTotals
  }

  public getProportion (key: string): number | undefined {
    if (!this.answerTotals) {
      return undefined
    }
    if (!this.answerTotals[key]) {
      return undefined
    }
    return this.answerTotals[key].proportion
  }
}

export class StatsByAnswer {
  @Expose() readonly correctAnswer: string
  @Expose() readonly proportion: number
  @Expose() readonly count: number

  constructor (correctAnswer: string, proportion: number, count: number) {
    this.correctAnswer = correctAnswer
    this.proportion = proportion
    this.count = count
  }
}

export class StatsByCorrectCount {
  @Expose() readonly correctAnswerCount: number
  @Expose() readonly proportion: number
  @Expose() readonly count: number

  constructor (correctAnswerCount: number, proportion: number, count: number) {
    this.correctAnswerCount = correctAnswerCount
    this.proportion = proportion
    this.count = count
  }
}

export class StatsByPoints {
  @Expose() readonly points: number
  @Expose() readonly proportion: number
  @Expose() readonly count: number

  constructor (points: number, proportion: number, count: number) {
    this.points = points
    this.proportion = proportion
    this.count = count
  }

  get pointsLabel (): string {
    const plural = (this.points === 1) ? '' : 's'
    return `${this.points} point${plural}`
  }
}

/**
 * This provides group answer stats for multiple-answer questions
 */
export class MultipleAnswerStats {
  @Expose()
  @Type(() => StatsByAnswer)
  readonly byAnswer?: StatsByAnswer[]

  @Expose()
  @Type(() => StatsByCorrectCount)
  readonly byCorrect?: StatsByCorrectCount[]

  @Expose()
  @Type(() => StatsByPoints)
  readonly byPoints?: StatsByPoints[]

  constructor (byAnswer: StatsByAnswer[], byCorrect: StatsByCorrectCount[], byPoints: StatsByPoints[]) {
    this.byAnswer = byAnswer
    this.byCorrect = byCorrect
    this.byPoints = byPoints
  }
}
