






































































import Vue, { PropType } from 'vue'
import AnswerStateIcon from '@/components/inputs/AnswerStateIcon.vue'
import ToggleInput from '@/components/inputs/ToggleInput.vue'
import { AnswerState, ViewPhase } from '@/model/gamephase'
import { RenderedQuestion } from '@/model/round'
import { QuestionSpec } from '@/model/question'
import { BaseInput } from '@/components/inputs/baseInput'

export default BaseInput().extend({
  name: 'AreYouSureTextInput',
  components: {
    AnswerStateIcon,
    ToggleInput
  },
  props: {
    playerView: {
      type: Boolean,
      required: false,
      default: true
    },
    renderedQuestion: {
      type: RenderedQuestion as PropType<RenderedQuestion<QuestionSpec>>,
      required: true
    }
  },
  data: function () {
    return {
      areYouSure: false,
      ViewPhase
    }
  },
  computed: {
    inputId (): string {
      return `yousure-input-${this.renderedQuestion.questionIx}`
    },
    answerState (): AnswerState | undefined {
      if (this.renderedQuestion.viewPhase !== ViewPhase.ANSWER) {
        return undefined
      }
      return this.renderedQuestion.getSubmittedAnswerOutcome(0)
    }
  },
  mounted () {
    // Handle remembered answers for areYouSure boolean
    this.areYouSure = this.answers[1] === 'yes'
    if (this.renderedQuestion.viewPhase === ViewPhase.ANSWER || this.renderedQuestion.locked) {
      const submittedYouSure = this.renderedQuestion.getSubmittedAnswer(1)
      if (submittedYouSure) {
        this.areYouSure = submittedYouSure.toLowerCase() === 'yes'
      }
    }
  },
  methods: {
    initializeData (): void {
      this.answers = ['', 'no']
    },
    handleToggleChange (): void {
      Vue.set(this.answers, 1, this.areYouSure ? 'yes' : 'no')
      this.handleInputAnswerChange()
    },
    handleEnterKey (event: Event): void {
      // Specifically for mobile ergonomics, lose focus so the mobile keyboard
      // retracts into the underbelly of the phone
      const targetEl = event.target as HTMLElement
      targetEl.blur()
    },
    nop (event: Event): boolean {
      event.preventDefault()
      event.stopPropagation()
      return false
    }
  }
})
