











import Vue, { PropType } from 'vue'
import { ViewPhase } from '@/model/gamephase'
import { SingleAnswerStats } from '@/model/answer'
import { RenderedQuestion } from '@/model/round'
import { QuestionSpec } from '@/model/question'

export default Vue.extend({
  name: 'SingleAnswer',
  computed: {
    answerStats (): SingleAnswerStats | undefined {
      return this.renderedQuestion.answerStats
    },
    correctPercent (): number {
      if (!this.answerStats || !this.answerStats.answerTotals) {
        return 0
      }
      return Math.round(100.0 * this.answerStats.answerTotals['<CORRECT>'].proportion)
    }
  },
  props: {
    playerView: {
      type: Boolean,
      required: false,
      default: true
    },
    renderedQuestion: {
      type: RenderedQuestion as PropType<RenderedQuestion<QuestionSpec>>,
      required: true
    }
  },
  data: function () {
    return {
      ViewPhase
    }
  }
})
