




























import Vue from 'vue'
import { PropType } from 'vue/types/umd'
import { QuestionSpec } from '@/model/question'
import { AnswerState, ViewPhase } from '@/model/gamephase'
import { RenderedQuestion } from '@/model/round'
import StatsByCorrectCount from '@/components/stats/StatsByCorrectCount.vue'
import StatsByPoints from '@/components/stats/StatsByPoints.vue'
import StatsByCorrectAnswer from '@/components/stats/StatsByCorrectAnswer.vue'

export default Vue.extend({
  name: 'MultipleAnswerStats',
  components: {
    StatsByCorrectCount,
    StatsByPoints,
    StatsByCorrectAnswer
  },
  props: {
    playerView: {
      type: Boolean,
      required: false,
      default: true
    },
    renderedQuestion: {
      type: RenderedQuestion as PropType<RenderedQuestion<QuestionSpec>>,
      required: true
    },
    showByPoints: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      ViewPhase,
      AnswerState
    }
  },
  computed: {
    playerCorrectAnswerCount (): number | undefined {
      if (this.renderedQuestion.viewPhase !== ViewPhase.ANSWER) {
        return undefined
      }
      return this.renderedQuestion.getPlayerCorrectAnswerCount()
    }
  }
})
