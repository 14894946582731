import { QuestionSpec } from '@/model/question'
import { RenderedQuestion } from '@/model/round'
import Vue, { PropType } from 'vue'

// In order to correctly get the generic typing for RenderedQuestion to propagate through
// the Vue.extend calls, we have to explicitly type the Vue.extend call which is why we need
// these interfaces
interface QProps<Q extends QuestionSpec> {
  playerView: boolean;
  answeringEnabled: boolean;
  roundIx: number;
  gameInstanceId: string;
  renderedQuestion: RenderedQuestion<Q>;
}
interface QMethods {
  handleQuestionAnswerChange (answers: string | string[], answerLabels?: string | string[]): void;
}

// Provides common props/methods/etc common to all question components
// without any template or render so in that sense it is equivalent
// to an abstract base class
//
// When you would like to extend this, you can:
//
// export default BaseQuestion<SomeQuestionType>().extend({
//   name: 'RealQuestionComponent',
//   components: { ... },
//   ...
// })
export function BaseQuestion <Q extends QuestionSpec> () {
  return Vue.extend<object, QMethods, object, QProps<Q>>({
    name: 'BaseQuestion',
    props: {
      // distinguishes between the player app and the host/sidekick app
      playerView: {
        type: Boolean,
        required: false,
        default: true
      },
      // is the user allowed to answer the question?
      answeringEnabled: {
        type: Boolean,
        required: false,
        default: false
      },
      roundIx: {
        type: Number,
        required: true
      },
      gameInstanceId: {
        type: String,
        required: true
      },
      renderedQuestion: {
        type: Object as PropType<RenderedQuestion<Q>>,
        required: true
      }
    },
    methods: {
      handleQuestionAnswerChange (answers: string[], answerLabels?: string[]): void {
        // Emit answer change up to Round
        this.$emit('onChange', this.renderedQuestion.questionIx, answers, answerLabels)
      }
    }
  })
}
