/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-namespace */
import Vue from 'vue'

export namespace UIUtils {
  const defaultScroll: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  }
  const defaultHighlight = '#fcff59'

  // i was trying to figure out how to export a css variable to be used for this default css colour
  // but my attempts failed and i'd rather get this out as is. This should actually probably just be a component
  // with props and state :/ but let's get this released as is. - bert
  /**
   * Takes an HTMLElement and 'highlights' it for a specified duration with a specified colour
   * @param el HTMLElement you wish to highlight
   * @param highlightColour optional colour to change the background colour to
   * @param duration  optional duration
   */
  export function highlight (el: HTMLElement, highlightColour = defaultHighlight, duration = 1000): void {
    let originalBG = el.style.backgroundColor
    let originalTransition = el.style.transition
    // this should only run once per element and store the truly original values
    if (!el.hasAttribute('originalBG')) {
      el.setAttribute('originalBG', originalBG)
      el.setAttribute('originalTransition', originalTransition)
    } else {
      originalBG = el.getAttribute('originalBG') as string
      originalTransition = el.getAttribute('originalTransition') as string
    }
    el.style.transition = 'background ' + duration / 1000 + 's'
    el.style.backgroundColor = highlightColour
    setTimeout(function () {
      el.style.backgroundColor = originalBG
      el.removeAttribute('highlighting')
      setTimeout(function () {
        el.style.transition = originalTransition
      }, duration)
    }, duration)
  }

  /**
   * Helper to scroll an element into view and highlight it for a short amount of time
   * @param el HTMLElement to scroll to and? highlight
   * @param highlight you don't have to highlight if you don't want to
   */
  export function scrollToAndHighlight (el: HTMLElement | undefined, highlight = true): void {
    if (el && el.scrollIntoView) {
      el.scrollIntoView(defaultScroll)
      if (highlight) {
        UIUtils.highlight(el)
      }
    }
  }

  /**
   * Smooth scroll the top of the document body into view
   */
  export function scrollToTop (): void {
    document.body.scrollIntoView(defaultScroll)
  }

  /**
   * Returns the HTMLElement associated with the ref (usually obtained via $refs.someRef) when possible. If an array is
   * passed in, only the first element of that array is inspected.
   *
   * If the referenced Element is _not_ an HTMLElement then undefined is returned.
   *
   * If an empty array or undefined is passed in, then undefined is returned.
   */
  export function getHTMLElementFromRef (ref: Vue | Element | Vue[] | Element[] | undefined): HTMLElement | undefined {
    if (!ref) {
      return undefined
    }
    let firstRef: Vue | Element
    if (Array.isArray(ref)) {
      if (ref.length === 0) {
        return undefined
      }
      firstRef = ref[0]
    } else {
      firstRef = ref
    }

    let el: Element
    if (firstRef instanceof Element) {
      el = firstRef
    } else {
      el = firstRef.$el
    }
    if (el instanceof HTMLElement) {
      return el
    }
    return undefined
  }
}
