import Vue from 'vue'

// Provides a simple event handler that stops event handling/propagation
//
// Example:
// <template>
//   <input type="text" @paste="nop" />
// </template>
const nopMixin = Vue.extend({
  methods: {
    nop (event: Event): boolean {
      event.preventDefault()
      event.stopPropagation()
      return false
    }
  }
})

export default nopMixin
