import { Expose } from 'class-transformer'
import { QuestionSpec, QuestionType, getArrayValueFromOneIndex } from '@/model/question'

export class MultipleChoiceQuestionSpec extends QuestionSpec {
  @Expose() readonly choices: string[]
  @Expose() readonly category: string
  @Expose() readonly subCategory: string
  @Expose() readonly questionText: string
  @Expose() readonly correctPointValue: number
  @Expose() readonly incorrectPointValue: number

  constructor (
    type: QuestionType,
    choices: string[],
    category: string,
    subCategory: string,
    questionText: string,
    correctPointValue: number,
    incorrectPointValue: number
  ) {
    super(type)
    this.choices = choices
    this.category = category
    this.subCategory = subCategory
    this.questionText = questionText
    this.correctPointValue = correctPointValue
    this.incorrectPointValue = incorrectPointValue
  }

  public getAnswerLabel (answerValue: string): string {
    return getArrayValueFromOneIndex(this.choices, answerValue)
  }
}
