export class User {
  public playerName?: string
  public justWatch = false
  public registering = false

  // Registration outcome details
  public lastUpdateError?: string
  public welcomeMessage?: string
  public welcomeImageUrl?: string

  constructor (playerName?: string, justWatch = false, lastUpdateError?: string) {
    this.playerName = playerName
    this.lastUpdateError = lastUpdateError
    this.justWatch = justWatch
  }

  get isRegistered (): boolean {
    return Boolean(this.playerName)
  }
}
