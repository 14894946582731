/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-namespace */

function convertToMs (value: number, scale: number): number {
  return Math.round(value * scale)
}

export namespace TimeUtils {
  export function secondsMs (seconds: number): number {
    return convertToMs(seconds, 1000)
  }

  export function minutesMs (minutes: number): number {
    return convertToMs(minutes, 60000)
  }

  export function hoursMs (hours: number): number {
    return convertToMs(hours, 3600000)
  }

  export function daysMs (days: number): number {
    return convertToMs(days, 86400000)
  }
}
