














































import { PropType } from 'vue'
import AnswerStateIcon from '@/components/inputs/AnswerStateIcon.vue'
import { AnswerState, ViewPhase } from '@/model/gamephase'
import { SingleTextQuestionSpec } from '@/model/question'
import { RenderedQuestion } from '@/model/round'
import nopMixin from '@/components/mixins/nopMixin'
import { BaseInput } from '@/components/inputs/baseInput'

export default BaseInput().extend({
  name: 'SingleTextInput',
  components: {
    AnswerStateIcon
  },
  mixins: [nopMixin],
  props: {
    playerView: {
      type: Boolean,
      required: false,
      default: true
    },
    renderedQuestion: {
      type: RenderedQuestion as PropType<RenderedQuestion<SingleTextQuestionSpec>>,
      required: true
    }
  },
  data: function () {
    return {
      ViewPhase
    }
  },
  methods: {
    initializeData (): void {
      this.answers = ['']
    },
    handleEnterKey (event: Event): void {
      // For mobile ergonomics, lose focus so the mobile keyboard
      // retracts into the underbelly of the phone
      const targetEl = event.target as HTMLElement
      targetEl.blur()
    }
  },
  computed: {
    inputId (): string {
      return `singletext-input-${this.renderedQuestion.questionIx}`
    },
    answerState (): AnswerState | undefined {
      if (this.renderedQuestion.viewPhase !== ViewPhase.ANSWER) {
        return undefined
      }
      return this.renderedQuestion.getSubmittedAnswerOutcome(0)
    }
  }
})
