


















import Vue from 'vue'
import { TextAreaFormField } from '@/model/registration'
import { PropType } from 'vue/types/umd'

export default Vue.extend({
  name: 'TextAreaInput',
  props: {
    value: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    field: {
      type: Object as PropType<TextAreaFormField>,
      required: true
    }
  },
  computed: {
    inputId (): string {
      return `textinput-${this.fieldName}`
    },
    rows (): number {
      return this.field.rows ?? 3
    }
  },
  methods: {
    onInput (e: InputEvent) {
      this.$emit('input', { value: (e.target as HTMLInputElement).value })
    }
  }
})
