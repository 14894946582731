
















































































import { PropType } from 'vue'
import AnswerStateIcon from '@/components/inputs/AnswerStateIcon.vue'
import { AnswerState, ViewPhase } from '@/model/gamephase'
import nopMixin from '@/components/mixins/nopMixin'
import { RenderedQuestion } from '@/model/round'
import { QuestionSpec } from '@/model/question'
import { BaseInput } from '@/components/inputs/baseInput'

/**
 * Because of detecting changes to arrays (https://vuejs.org/v2/guide/reactivity.html#For-Arrays)
 * we'll pre-initialize a full answers array to the known size
 */
function initializeAnswers (playerAnswerCount: number): string[] {
  const answers = []
  for (let i = 0; i < playerAnswerCount; i++) {
    answers.push('')
  }
  return answers
}

export default BaseInput().extend({
  name: 'MultipleTextInput',
  mixins: [nopMixin],
  components: {
    AnswerStateIcon
  },
  props: {
    playerView: {
      type: Boolean,
      required: false,
      default: true
    },
    renderedQuestion: {
      type: RenderedQuestion as PropType<RenderedQuestion<QuestionSpec>>,
      required: true
    },
    playerAnswerCount: {
      type: Number,
      required: true
    },
    showAnswerNumbers: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: function () {
    return {
      ViewPhase,
      AnswerState
    }
  },
  methods: {
    initializeData () {
      this.answers = initializeAnswers(this.playerAnswerCount)
    },
    handleEnterKey (event: Event, answerIdx: number): void {
      if (answerIdx >= this.playerAnswerCount - 1) {
        // This is the last input, so just lose focus
        const targetEl = event.target as HTMLElement
        targetEl.blur()
      } else {
        // Try to focus the next input in this question
        const focusIdx = answerIdx + 1
        const targetEl = (this.$refs.inputFields as HTMLElement[])[focusIdx]
        targetEl.focus()
      }
    }
  },
  computed: {
    uniqueInputId (): string {
      return `multitext-input-${this.renderedQuestion.questionIx}`
    },
    inputType (): string {
      return (this.renderedQuestion.question).isNumericOnly
        ? 'tel'
        : 'text'
    },
    submittedAnswers (): object[] {
      const res: object[] = []
      for (let i = 0; i < this.playerAnswerCount; i++) {
        const playerAnswer = this.renderedQuestion.getSubmittedAnswer(i)
        const playerAnswerOutcome = this.renderedQuestion.getSubmittedAnswerOutcome(i)
        res.push({
          playerAnswer: playerAnswer,
          playerAnswerOutcome: playerAnswerOutcome
        })
      }
      return res
    }
  }
})
