



































import Vue, { PropType } from 'vue'
import { FormField } from '@/model/registration'
import TextInput from '@/components/form/TextInput.vue'
import CheckboxInput from '@/components/form/CheckboxInput.vue'
import TextAreaInput from '@/components/form/TextAreaInput.vue'
import SelectInput from '@/components/form/SelectInput.vue'

export default Vue.extend({
  name: 'DynamicForm',
  components: {
    TextInput,
    TextAreaInput,
    CheckboxInput,
    SelectInput
  },
  methods: {
    update (key: string, value: string) {
      // Emit input event for v-model binding on user of this component
      this.$emit('input', { ...this.value, [key]: value })
      if (!this.formUsed) {
        this.formUsed = true
        this.$emit('firstUse')
      }
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    fields: {
      type: Map as PropType<Map<string, FormField>>,
      required: true
    }
  },
  data () {
    return {
      formUsed: false
    }
  }
})
