import { Expose, Type } from 'class-transformer'
import { QuestionSpec, QuestionType } from '@/model/question'
import { AnswerState, ViewPhase } from '@/model/gamephase'
import { MultipleAnswerStats, SingleAnswerStats } from '@/model/answer'
import * as QS from '@/model/question'

export class RenderedQuestion<QUESTION extends QuestionSpec> {
  @Expose() readonly questionIx: number
  @Expose() readonly questionLabel: string

  @Expose()
  @Type(() => QuestionSpec, {
    discriminator: {
      property: 'type',
      subTypes: [
        { value: QS.SingleTextQuestionSpec, name: QuestionType.SINGLETEXT },
        { value: QS.MultipleTextQuestionSpec, name: QuestionType.MULTITEXT_ORDERED },
        { value: QS.MultipleTextQuestionSpec, name: QuestionType.MULTITEXT_UNORDERED },
        { value: QS.MultipleChoiceQuestionSpec, name: QuestionType.MULTICHOICE },
        { value: QS.SingleTextQuestionSpec, name: QuestionType.SINGLENUMERIC_RANGE },
        { value: QS.MultipleTextQuestionSpec, name: QuestionType.MULTINUMERIC_RANGE_ORDERED },
        { value: QS.MultipleTextQuestionSpec, name: QuestionType.MULTINUMERIC_RANGE_UNORDERED },
        { value: QS.SecondGuessTextQuestionSpec, name: QuestionType.GWD_SECOND_GUESS_YOURSELF },
        { value: QS.DoubleDownQuestionSpec, name: QuestionType.DOUBLE_DOWN },
        { value: QS.TwoOfThreeTextQuestionSpec, name: QuestionType.TWO_OF_THREE_TEXT },
        { value: QS.TwoOfThreeTextQuestionSpec, name: QuestionType.TWO_OF_THREE_NUMERIC },
        { value: QS.YouSureTextQuestionSpec, name: QuestionType.YOU_SURE_TEXT },
        { value: QS.YouSureTextQuestionSpec, name: QuestionType.YOU_SURE_NUMERIC }
      ]
    },
    keepDiscriminatorProperty: true
  })
  readonly question: QUESTION

  @Expose() readonly displayNotes?: string
  @Expose() readonly locked: boolean
  @Expose() readonly viewPhase: ViewPhase
  @Expose() readonly scored: boolean

  @Expose() private readonly submittedAnswer?: string | string[]

  @Expose() readonly submittedAnswerState?: AnswerState | AnswerState[] // Multichoice/Singletext
  @Expose() readonly answerOutcome?: AnswerState[] // Multitext

  @Expose() readonly pointsOutcome?: number // only Multitext so far
  @Expose() readonly showPoints: boolean

  @Expose()
  @Type(() => SingleAnswerStats)
  readonly answerStats?: SingleAnswerStats

  @Expose()
  @Type(() => MultipleAnswerStats)
  readonly multipleAnswerStats?: MultipleAnswerStats

  constructor (
    questionIx: number,
    questionLabel: string,
    question: QUESTION,
    displayNotes: string | undefined,
    locked: boolean,
    viewPhase: ViewPhase,
    scored: boolean,
    submittedAnswer: string | string[] | undefined,
    showPoints: boolean,
    submittedAnswerState?: AnswerState | AnswerState[],
    answerOutcome?: AnswerState[],
    answerStats?: SingleAnswerStats,
    pointsOutcome?: number,
    multipleAnswerStats?: MultipleAnswerStats
  ) {
    this.questionIx = questionIx
    this.questionLabel = questionLabel
    this.question = question
    this.displayNotes = displayNotes
    this.locked = locked
    this.viewPhase = viewPhase
    this.scored = scored
    this.showPoints = showPoints
    this.submittedAnswer = submittedAnswer
    this.submittedAnswerState = submittedAnswerState
    this.answerOutcome = answerOutcome
    this.answerStats = answerStats
    this.pointsOutcome = pointsOutcome
    this.multipleAnswerStats = multipleAnswerStats
  }

  public adjustForTestHarness (questionIx: number, viewPhase: ViewPhase, locked: boolean): RenderedQuestion<QUESTION> {
    return new RenderedQuestion(
      questionIx,
      this.questionLabel,
      this.question,
      this.displayNotes,
      locked,
      viewPhase,
      this.scored,
      this.submittedAnswer,
      this.showPoints,
      this.submittedAnswerState,
      this.answerOutcome,
      this.answerStats,
      this.pointsOutcome,
      this.multipleAnswerStats
    )
  }

  // TBD: We'll likely re-model the submittedAnswers field as
  // string[] instead of string | string[]
  public getSubmittedAnswer (idx: number): string {
    if (this.submittedAnswer === undefined || this.submittedAnswer === null) {
      return ''
    }
    if (!(this.submittedAnswer instanceof Array)) {
      if (idx === 0) {
        return this.submittedAnswer as string
      } else {
        return ''
      }
    } else if (this.submittedAnswer instanceof Array) {
      if (idx >= this.submittedAnswer.length) {
        return ''
      } else {
        return this.submittedAnswer[idx]
      }
    } else {
      // Our type-hubris suggests this is impossible
      return ''
    }
  }

  public getRawSubmittedAnswers (): string | string[] | undefined {
    return this.submittedAnswer
  }

  public getSubmittedAnswersLabel (): string | undefined {
    if (this.submittedAnswer === undefined || this.submittedAnswer === null) {
      return undefined
    }
    if (!(this.submittedAnswer instanceof Array)) {
      return this.question.getAnswerLabel(this.submittedAnswer)
    } else if (this.submittedAnswer instanceof Array) {
      return this.submittedAnswer
        .filter((value: string) => {
          return value !== undefined && value !== null && value !== ''
        })
        .map((value: string): string => {
          return this.question.getAnswerLabel(value)
        })
        .join(', ')
    }
  }

  public hasSubmittedAnswers (): boolean {
    return this.submittedAnswer !== undefined && this.submittedAnswer !== null
  }

  // TBD: We'll likely re-model submittedAnswerState/answerOutcome to combine
  // but for now this will expose a common interface
  public getSubmittedAnswerOutcome (idx: number): AnswerState | undefined {
    if (this.submittedAnswerState !== undefined && this.submittedAnswerState !== null) {
      if (!(this.submittedAnswerState instanceof Array)) {
        if (idx === 0) {
          return this.submittedAnswerState as AnswerState
        } else {
          return undefined
        }
      } else if (this.submittedAnswerState instanceof Array) {
        if (idx >= this.submittedAnswerState.length) {
          return undefined
        } else {
          return this.submittedAnswerState[idx]
        }
      } else {
        // Our type-hubris suggests this is impossible
        return undefined
      }
    } else if (this.answerOutcome !== undefined && this.answerOutcome !== null) {
      if (idx >= 0 && idx < this.answerOutcome.length) {
        return this.answerOutcome[idx]
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }

  public getPlayerCorrectAnswerCount (): number {
    if (this.submittedAnswerState !== undefined && this.submittedAnswerState !== null) {
      if (this.submittedAnswerState === AnswerState.CORRECT) {
        return 1
      } else {
        return 0
      }
    } else if (this.answerOutcome !== undefined && this.answerOutcome !== null) {
      return this.answerOutcome.filter((outcome: AnswerState) => {
        return outcome === AnswerState.CORRECT
      }).length
    }
    return 0
  }
}

export class RoundMultiplier {
  @Expose() readonly availableCount: number
  @Expose() readonly show: boolean

  constructor (availableCount: number, show: boolean) {
    this.availableCount = availableCount
    this.show = show
  }
}

export enum RoundQuestionMode {
  ONE = 'ONE',
  INCR = 'INCR',
  ALL = 'ALL',
}

export enum RoundAnswerMode {
  ONE = 'ONE',
  INCR = 'INCR',
  ALL = 'ALL',
}

export class RenderedRound {
  @Expose() readonly roundIx: number
  @Expose() readonly roundLabel?: string
  @Expose() readonly roundDescription?: string

  @Expose() readonly roundLength: number
  @Expose() readonly roundQuestionMode: RoundQuestionMode
  @Expose() readonly roundAnswerMode: RoundAnswerMode

  @Expose()
  @Type(() => RenderedQuestion)
  readonly renderedQuestions: RenderedQuestion<QuestionSpec>[]

  @Expose() readonly showTimer: boolean
  @Expose() readonly showSubmitButton: boolean

  @Expose()
  @Type(() => RoundMultiplier)
  readonly joker?: RoundMultiplier

  constructor (
    roundIx: number,
    roundLength: number,
    roundQuestionMode: RoundQuestionMode,
    roundAnswerMode: RoundAnswerMode,
    renderedQuestions: RenderedQuestion<QuestionSpec>[],
    showTimer: boolean,
    showSubmitButton: boolean,
    roundLabel?: string,
    roundDescription?: string,
    joker?: RoundMultiplier
  ) {
    this.roundIx = roundIx
    this.roundLength = roundLength
    this.roundQuestionMode = roundQuestionMode
    this.roundAnswerMode = roundAnswerMode
    this.renderedQuestions = renderedQuestions
    this.showTimer = showTimer
    this.showSubmitButton = showSubmitButton
    this.roundLabel = roundLabel
    this.roundDescription = roundDescription
    this.joker = joker
  }

  public getRoundPhase (): ViewPhase | undefined {
    if (this.renderedQuestions === null || this.renderedQuestions === undefined) {
      return undefined
    }
    if (this.renderedQuestions.length === 0) {
      return undefined
    }
    const distinctPhases = [...new Set(this.renderedQuestions.map((rq: RenderedQuestion<QuestionSpec>) => {
      return rq.viewPhase
    }))]
    if (distinctPhases.length === 1) {
      return distinctPhases[0]
    } else {
      return undefined
    }
  }
}
