





















import Vue from 'vue'
import { PropType } from 'vue/types/umd'
import PercentFilledBar from '@/components/misc/PercentFilledBar.vue'
import { StatsByCorrectCount } from '@/model/answer'

export default Vue.extend({
  name: 'StatsByCorrectCount',
  components: {
    PercentFilledBar
  },
  props: {
    byCorrect: {
      type: Array as PropType<StatsByCorrectCount[]>,
      required: true
    },
    highlightCorrect: {
      type: Number,
      required: false
    }
  }
})
