import 'mutationobserver-shim'
import 'reflect-metadata'
import Vue from 'vue'
import PlayerApp from '@/components/player/PlayerApp.vue'
import HostApp from '@/components/host/HostApp.vue'
import store, { GameConfig } from '@/store'
import { Message, SubmittedAnswersMessage } from '@/model/rpc'
import { LocalStorage } from '@/utils/localstorage'

Vue.config.productionTip = false

// NOTE: All of these methods/fields should be optional as we can't
// necessarily guarantee their presence on window
export type AugmentedWindow = Window & typeof globalThis & {
  // vueXXX methods are attached on Window by Vue
  vueMountPlayer?: (rootSelector: string) => void;
  vueMountHost?: (rootSelector: string) => void;
  vueReceiveMessage?: (type: string, body: object) => void;
  // shinyXXX methods are attached on Window by Shiny
  shinyReceiveRegistration?: (regFields: { [fieldName: string]: string }) => void;
  shinyReceiveAnswers?: (answersToSubmit: SubmittedAnswersMessage) => void;
  // vueGameConfig added to Window by Shiny on initial page render
  vueGameConfig?: GameConfig;
}

export function mountPlayer (rootSelector: string) {
  const app = new Vue({
    store: store.original,
    render: h => h(PlayerApp),
    mounted: () => {
      store.dispatch.loadConfig()
      // Perform an initial localstorage cleanup shortly, and then infrequently thereafter
      setTimeout(() => {
        LocalStorage.expireOldObjects()
      }, 5000)
      setInterval(() => {
        LocalStorage.expireOldObjects()
      }, 1000 * 60 * 30)
    }
  })
  app.$mount(rootSelector)
}

export function mountHost (rootSelector: string) {
  const app = new Vue({
    store: store.original,
    render: h => h(HostApp)
  })
  app.$mount(rootSelector)
}

if (window) {
  (window as AugmentedWindow).vueMountPlayer = (rootSelector: string) => {
    mountPlayer(rootSelector)
  }

  (window as AugmentedWindow).vueMountHost = (rootSelector: string) => {
    mountHost(rootSelector)
  }

  // Running inside a browser, attach a vueReceiveMessage(type, body)
  // function which will dispatch the receiveMessage action
  // on the Vuex store
  // From global JS on the page, this can be invoked as:
  //   vueReceiveMessage('MessageTypeXX', {a: 'value', b: 23})
  (window as AugmentedWindow).vueReceiveMessage = (type: string, body: object) => {
    store.dispatch.receiveMessage(new Message(type, body))
  }
}

// Attempt to auto-mount conventional locations
export function autoMount () {
  if (document) {
    const playerEl = document.getElementById('vue-player')
    const hostEl = document.getElementById('vue-host')
    if (playerEl) {
      mountPlayer('#vue-player')
    } else if (hostEl) {
      mountHost('#vue-host')
    }
  }
}

autoMount()
