



























import { RoundMultiplier } from '@/model/round'
import Vue from 'vue'

export default Vue.extend({
  name: 'JokerOption',
  props: {
    joker: {
      type: RoundMultiplier,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    jokerDisabled (): boolean {
      return this.disabled || this.joker.availableCount < 1
    }
  },
  methods: {
    handleInput (event: Event) {
      if (this.jokerDisabled) {
        return
      }
      if (event.target) {
        const target: HTMLInputElement = event.target as HTMLInputElement
        this.$emit('input', target.checked)
      }
    }
  }
})
