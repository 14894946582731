


















import Vue from 'vue'

export interface QuestionHeaderProps {
  locked: boolean;
  questionLabel: string;
}

export default Vue.extend<unknown, unknown, unknown, QuestionHeaderProps>({
  name: 'QuestionHeader',
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    questionLabel: {
      type: String,
      required: true
    }
  }
})
