import { Expose } from 'class-transformer'

export const MAGIC_SKIP_VALUE = '<SKIP>'

export enum QuestionType {
  MULTICHOICE = 'MULTICHOICE_QUESTION',
  SINGLETEXT = 'SINGLETEXT_QUESTION',
  MULTITEXT_UNORDERED = 'UNORDERED_MULTITEXT_QUESTION',
  MULTITEXT_ORDERED = 'ORDERED_MULTITEXT_QUESTION',
  GWD_SECOND_GUESS_YOURSELF = 'GWD_SECOND_GUESS_YOURSELF_QUESTION',
  SINGLENUMERIC_RANGE = 'SINGLENUMERIC_RANGE_QUESTION',
  MULTINUMERIC_RANGE_UNORDERED = 'UNORDERED_MULTINUMERIC_RANGE_QUESTION',
  MULTINUMERIC_RANGE_ORDERED = 'ORDERED_MULTINUMERIC_RANGE_QUESTION',
  DOUBLE_DOWN = 'DOUBLEDOWN_QUESTION',
  TWO_OF_THREE_TEXT = 'TWO_OF_THREE_TEXT_QUESTION',
  TWO_OF_THREE_NUMERIC = 'TWO_OF_THREE_NUMERIC_QUESTION',
  YOU_SURE_TEXT = 'YOU_SURE_TEXT_QUESTION',
  YOU_SURE_NUMERIC = 'YOU_SURE_NUMERIC_QUESTION'
}

export abstract class QuestionSpec {
  @Expose() readonly type: QuestionType;
  @Expose() readonly imageUrl?: string;

  constructor (type: QuestionType) {
    this.type = type
  }

  get isNumericOnly (): boolean {
    return this.type === QuestionType.MULTINUMERIC_RANGE_ORDERED ||
           this.type === QuestionType.MULTINUMERIC_RANGE_UNORDERED ||
           this.type === QuestionType.SINGLENUMERIC_RANGE ||
           this.type === QuestionType.TWO_OF_THREE_NUMERIC ||
           this.type === QuestionType.YOU_SURE_NUMERIC
  }

  /**
   * Get the appropriate type for the HTML input element. Currently
   * only handles using the "tel" input type for numeric questions
   * for a good mobile keypad experience.
   *
   * @returns html input type suitable for input HTML element
   */
  get htmlInputType (): string {
    return this.isNumericOnly
      ? 'tel'
      : 'text'
  }

  // For most questions, the answerValue is the same as the answerLabel
  // but this allows various question types to override this behavior.
  // In particular, multichoice questions map 1-indexed values to labels
  public getAnswerLabel (answerValue: string): string {
    return answerValue
  }
}

/**
 * For some question types (multichoice, multiselect), the submitted answer is not
 * the label of the selected answer, but the index into the array of choices. Also,
 * that index is 1-indexed instead of 0-indexed and the value is rendered as a string
 * and not a number.
 *
 * This function takes the resulting answer value and determines the corresponding answer
 * label.
 *
 * If oneIdxStr is not numeric, or it is not between 1 and the length of the arr
 * inclusive, then we simply return oneIdxStr.
 *
 * Given arr = ['Mur', 'Bret', 'Shawn', 'Anybody Else']:
 * getArrayValueFromOneIndex(arr, '2') === 'Bret'
 * getArrayValueFromOneIndex(arr, '4') === 'Anybody Else'
 * getArrayValueFromOneIndex(arr, '0') === '0' (echo input with invalid index)
 *
 * @param arr array of values
 * @param oneIdxStr desired index into arr as one-indexed value as string
 */
export function getArrayValueFromOneIndex (arr: string[], oneIdxStr: string): string {
  const oneIdx = parseInt(oneIdxStr)
  if (isNaN(oneIdx)) {
    return oneIdxStr
  }
  const zeroIdx = oneIdx - 1
  if (zeroIdx >= 0 && zeroIdx < arr.length) {
    return arr[zeroIdx]
  } else {
    return oneIdxStr
  }
}
