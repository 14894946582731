






















import Vue from 'vue'

export default Vue.extend({
  name: 'ToggleInput',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    onLabel: {
      type: String,
      required: true
    },
    offLabel: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    valueLabel (): string {
      return this.checked ? this.onLabel : this.offLabel
    }
  },
  methods: {
    handleChange (e: InputEvent) {
      this.$emit('change', (e.target as HTMLInputElement).checked)
    }
  }
})
