import { VueConstructor } from 'vue'
import MultipleChoiceQuestion from '@/components/question/MultipleChoiceQuestion.vue'
import MultipleSelectQuestion from '@/components/question/MultipleSelectQuestion.vue'
import MultipleTextQuestion from '@/components/question/MultipleTextQuestion.vue'
import SecondGuessYourselfQuestion from '@/components/question/SecondGuessYourselfQuestion.vue'
import SingleTextQuestion from '@/components/question/SingleTextQuestion.vue'
import TwoOfThreeQuestion from '@/components/question/TwoOfThreeQuestion.vue'
import YouSureQuestion from '@/components/question/YouSureQuestion.vue'
import { QuestionType } from '@/model/question'

const QUESTION_COMPONENT_MAP: Record<string, VueConstructor<Vue>> = {
  [QuestionType.MULTICHOICE]: MultipleChoiceQuestion,

  [QuestionType.MULTITEXT_UNORDERED]: MultipleTextQuestion,
  [QuestionType.MULTITEXT_ORDERED]: MultipleTextQuestion,
  [QuestionType.MULTINUMERIC_RANGE_ORDERED]: MultipleTextQuestion,
  [QuestionType.MULTINUMERIC_RANGE_UNORDERED]: MultipleTextQuestion,

  [QuestionType.SINGLETEXT]: SingleTextQuestion,
  [QuestionType.SINGLENUMERIC_RANGE]: SingleTextQuestion,

  [QuestionType.GWD_SECOND_GUESS_YOURSELF]: SecondGuessYourselfQuestion,

  [QuestionType.DOUBLE_DOWN]: MultipleSelectQuestion,

  [QuestionType.TWO_OF_THREE_TEXT]: TwoOfThreeQuestion,
  [QuestionType.TWO_OF_THREE_NUMERIC]: TwoOfThreeQuestion,

  [QuestionType.YOU_SURE_TEXT]: YouSureQuestion,
  [QuestionType.YOU_SURE_NUMERIC]: YouSureQuestion
}

function extractUniqueComponents (obj: Record<string, VueConstructor<Vue>>): { [key: string]: VueConstructor<Vue> } {
  const components: { [key: string]: VueConstructor<Vue> } = {}
  Object.keys(obj).forEach((key: string) => {
    const val = QUESTION_COMPONENT_MAP[key]
    if (!components[key]) {
      components[key] = val
    }
  })
  return components
}

export const QUESTION_COMPONENTS: { [key: string]: VueConstructor<Vue> } = extractUniqueComponents(QUESTION_COMPONENT_MAP)

export function getQuestionComponent (questionType: QuestionType): VueConstructor<Vue> {
  const comp = QUESTION_COMPONENT_MAP[questionType]
  if (!comp) {
    console.debug('Unknown Question Type: ', questionType)
  }
  return comp
}
