












































import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import GamePhase from '@/model/gamephase'
import store from '@/store/index'
import PlayerRound from '@/components/player/PlayerRound.vue'
import PlayerRegistration from '@/components/player/PlayerRegistration.vue'

export default Vue.extend({
  name: 'PlayerApp',

  components: {
    PlayerRound,
    PlayerRegistration
  },

  data () {
    return {
      regSuccessShown: false
    }
  },

  computed: {
    showReg (): boolean {
      if (store.getters.userIsWatching) {
        return false
      }
      return store.state.gamePhase === GamePhase.PRE_GAME ||
        (store.state.gamePhase === GamePhase.IN_GAME && store.state.regConfig.allowInGame && !store.getters.userIsPlaying)
    },
    showRoundPanel (): boolean {
      return store.state.gamePhase === GamePhase.IN_GAME && (store.state.renderedRound !== undefined)
    },
    ...mapGetters(['userIsWatching', 'inGameRegAllowed']),
    ...mapState(['gameInstanceId', 'gameReady', 'gameConnected', 'gamePhase', 'renderedRound', 'roundTimer', 'user', 'regConfig'])
  },

  methods: {
    nop (): boolean {
      return false
    },
    disableWatching () {
      store.dispatch.justWatch(false)
    }
  }
})
