





















import Vue from 'vue'
import { PropType } from 'vue/types/umd'
import PercentFilledBar from '@/components/misc/PercentFilledBar.vue'
import { StatsByPoints } from '@/model/answer'

export default Vue.extend({
  name: 'StatsByPoints',
  components: {
    PercentFilledBar
  },
  props: {
    byPoints: {
      type: Array as PropType<StatsByPoints[]>,
      required: true
    },
    highlightPoints: {
      type: Number,
      required: false
    }
  }
})
