
















import Vue from 'vue'
import { PropType } from 'vue/types/umd'
import PercentFilledBar from '@/components/misc/PercentFilledBar.vue'
import { StatsByAnswer } from '@/model/answer'

export default Vue.extend({
  name: 'StatsByCorrectAnswer',
  components: {
    PercentFilledBar
  },
  props: {
    byAnswer: {
      type: Array as PropType<StatsByAnswer[]>,
      required: true
    }
  }
})
